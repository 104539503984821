<template>
  <div
    :class="
      'level color' +
      (type ? ' -' + type : '') +
      (isEnabled() && isOpen ? '' : ' -disabled') +
      (isForPdf ? ' -pdf' : '')
    "
    v-tooltip="
      !isEnabled() && skill.fieldSkills && skill.fieldSkills.length
        ? { title: $t('dashboard.skill_lock') }
        : { title: '' }
    "
  >
    <label
      v-for="item in maxSkillLevel"
      :key="item"
      :class="
        'level__label' +
        (isSelected(item) || hoverValue >= item ? ' -selected' : '') +
        (isForPdf ? ' -pdf' : '') +
        ((!isEnabled() || !isOpen) &&
        (!isSelected(item) || hoverValue >= item) &&
        !isStudeaManagerRoute
          ? ' grey'
          : '')
      "
      :for="skill.id + '-' + item"
      @click="select(item)"
      @mouseleave="leave"
      @mouseover="hover(item)"
    >
      <input
        :id="skill.id + '-' + item"
        v-model="vmodel"
        :disabled="!isEnabled() || !isOpen"
        :name="skill.id"
        :value="item"
        class="level__input"
        type="radio"
        @change="select(item)"
      />
    </label>
  </div>
  <button
    v-if="isEnabled() && isOpen"
    :class="'btn-icon color ' + (type ? ' -' + type : '')"
    v-tooltip="{ title: $t('resetSkill') }"
    @click="refreshSkill(skill.id)"
  >
    <FontAwesomeIcon icon="fa-light fa-rotate-right" />
  </button>

  <div
    v-if="type && skillItem[type + 'Value']"
    :class="'color -' + type + (isForPdf ? ' -pdf' : '')"
  >
    {{ skillItem[type + "Value"] ? skillItem[type + "Value"].assessment : "" }}
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";

export default {
  name: "SkillLevelComponent",
  components: {},
  props: {
    skill: {
      type: Object,
      required: true,
    },
    reply: {
      type: Object,
      required: false,
      default: () => null,
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    profile: {
      type: Number,
      required: false,
      default: 0,
    },
    maxSkillLevel: {
      type: Number,
      required: false,
      default: 0,
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
    isForPdf: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      skillItem: this.skill,
      hoverValue: 0,
      isStudeaManagerRoute: false,
      isOpen: false,
    };
  },
  watch: {
    currentSession() {
      this.updateModel();
    },
  },
  emits: ["select"],
  computed: {
    ...mapState(useTriadStore, {
      currentSession: (store) => store.currentSession,
    }),
    vmodel() {
      return this.skillItem[this.type + "Value"]
        ? this.skillItem[this.type + "Value"].value
        : null;
    },

    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  mounted() {
    this.updateModel();
    this.isStudeaManagerRoute = this.$route.path
      .split("/")
      .includes("studea-manager");
  },
  methods: {
    isSelected(value) {
      return (
        (this.admin && this.skill.requiredValue >= value) ||
        (this.skillItem[this.type + "Value"] &&
          this.skillItem[this.type + "Value"].value >= value)
      );
    },

    updateModel() {
      let value = null;
      let assessment = null;
      let skillAnswer;
      if (this.reply && this.reply.skillAnswers) {
        skillAnswer = this.reply.skillAnswers.filter(
          (item) => item.skill.id === this.skill.id,
        );
      }
      if (skillAnswer && skillAnswer[skillAnswer.length - 1]) {
        value = skillAnswer[skillAnswer.length - 1].value;
        assessment = skillAnswer[skillAnswer.length - 1].assessment;
      }
      this.skillItem[this.type + "Value"] = {
        value: value,
        assessment: assessment,
      };
      if (this.triad.userRoles && this.triad.userRoles.includes(this.profile)) {
        this.$emit("select", { skill: this.skillItem, type: this.type });
      }
      this.setIsOpen();
    },

    isEnabled() {
      return (
        this.triad &&
        this.triad.userRoles &&
        this.triad.userRoles.includes(this.profile) &&
        this.triad.trainingCourse &&
        this.triad.trainingCourse.skillRoles.includes(this.profile) &&
        this.skill.fieldSkills &&
        !this.skill.fieldSkills.length
      );
    },

    setIsOpen() {
      this.isOpen = this.currentSession.open;
    },

    hover(value) {
      this.hoverValue = value;
    },

    leave() {
      this.hoverValue = 0;
    },

    select(value) {
      this.skillItem[this.type + "Value"] = this.skillItem[this.type + "Value"]
        ? this.skillItem[this.type + "Value"]
        : [];
      this.skillItem[this.type + "Value"]["value"] = value;
      this.$emit("select", { skill: this.skillItem, type: this.type });
    },

    refreshSkill() {
      this.skillItem[this.type + "Value"].value = 0;
      this.$emit("select", { skill: this.skillItem, type: this.type });
    },
  },
};
</script>

<style lang="scss" scoped>
.color {
  height: 45px;
  color: var(--skill-color);
  --skill-color: var(--primary-color);

  &.-apprentice {
    --skill-color: #6ac7a4;
  }

  &.-tutor {
    --skill-color: #00275e;
  }

  &.-apprenticeMaster {
    --skill-color: #da5164;
  }

  &.-studeaManager {
    --skill-color: #f8a007;
  }

  .btn-icon {
    color: var(--skill-color);
  }

  &.-pdf {
    font-size: 10px;
  }
}

.level {
  border-radius: 6px;
  overflow: hidden;
  display: inline-block;
  height: 22px;
  border: 1px solid var(--skill-color);

  &.-pdf {
    height: 15px;
  }

  &.-disabled {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: none;
      z-index: 2;
      cursor: not-allowed;
    }
  }

  &__input {
    display: none;
  }

  &__label {
    width: 22px;
    height: 22px;
    background: $white;
    border-right: 1px solid var(--skill-color);
    cursor: pointer;

    &.-selected {
      background: var(--skill-color);
      border-right-color: $white;
    }

    &.grey {
      background: #cecece;
      border-right-color: $white;
    }

    &:last-child {
      border-right: 0;
    }

    &.-pdf {
      height: 16px;
      width: 16px;
    }
  }
}

.btn-icon {
  margin-left: 5px;
  height: 22px;
  position: relative;
  top: -4px;
}
</style>
