<template>
  <div
    v-for="questionnaire in category.originalQuestionnaires"
    :key="questionnaire.id"
    :style="
      '--max-width:' +
      199 *
        (category.originalQuestionnaires &&
        category.originalQuestionnaires.length
          ? category.originalQuestionnaires.length
          : 1) +
      'px'
    "
    class="dashboard__replies__questionnaires__item"
  >
    <div v-for="crew in crews" :key="crew.id">
      <div
        v-if="crew.triads.length"
        :class="'dashboard__replies__crew on crew-' + crew.id"
        :style="
          '--max-height:' +
          135 * (crew.triads.length ? crew.triads.length : 1) +
          'px'
        "
      >
        <div class="offset"></div>
        <div class="dashboard__replies__crew__triads">
          <div
            v-for="triad in crew.triads"
            :key="triad.id"
            class="dashboard__replies__triad"
          >
            <AccordionCellComponent
              :crew="crew"
              :questionnaire="questionnaire"
              :triad="triad"
              :type="type"
              @mail-relance="$emit('mailRelance')"
              @manage="manage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccordionCellComponent from "@/components/table/accordion/AccordionCellComponent.vue";

export default {
  name: "AccordionSubColumnComponent",
  components: { AccordionCellComponent },
  props: {
    type: {
      type: String,
      require: true,
    },
    category: {
      required: true,
    },
    crews: {
      type: Array,
      required: true,
    },
  },
  methods: {
    manage(payload) {
      this.$emit("manage", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__replies {
    &__item {
      &.on {
        .dashboard__replies__questionnaires__item {
          width: 199px;
          border-right: 1px solid #e0e6ed;

          &:last-child {
            border: none;
          }
        }

        .dashboard__replies__crew__triads {
          width: 150px;
        }
      }
    }

    &__questionnaires {
      &__item {
        text-align: center;
        transition: width 300ms ease-in-out;
        width: 0;
      }
    }

    &__crew {
      min-width: 200px;
      margin-top: 40px;

      padding: 20px;
      border-bottom: 1px solid #e0e6ed;

      &.on {
        .dashboard__replies__crew__triads {
          height: var(--max-height);
        }
      }

      &__triads {
        height: 0;
        overflow: hidden;
        width: 0;
        transition: all 300ms ease-in-out;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &__triad {
      border-bottom: 1px dashed #e0e6ed;
      margin-top: 15px;
      height: 125px;
      overflow: hidden;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      &:first-child {
        margin-top: 10px;
      }
    }
  }
}
</style>
