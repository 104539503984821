<template>
  <div
    :style="
      '--max-height:' +
      94 * (crew.triads.length ? crew.triads.length : 1) +
      'px'
    "
  >
    <div>
      <AccordionPersonReplyComponent
        :reply="apprenticeReply"
        :triad="triad"
        :questionnaire="questionnaire"
        profile="1"
        @mail-relance="$emit('mailRelance')"
        @manage="manage"
      />
    </div>
    <div>
      <AccordionPersonReplyComponent
        :reply="tutorReply"
        :triad="triad"
        :questionnaire="questionnaire"
        profile="2"
        @mail-relance="$emit('mailRelance')"
        @manage="manage"
      />
    </div>
    <div>
      <AccordionPersonReplyComponent
        :reply="apprenticeMasterReply"
        :triad="triad"
        :questionnaire="questionnaire"
        profile="3"
        @mail-relance="$emit('mailRelance')"
        @manage="manage"
      />
    </div>
    <div>
      <AccordionPersonReplyComponent
        :reply="studeaManagerReply"
        :triad="triad"
        :questionnaire="questionnaire"
        profile="4"
        @mail-relance="$emit('mailRelance')"
        @manage="manage"
      />
    </div>
  </div>
</template>

<script>
import AccordionPersonReplyComponent from "@/components/table/accordion/AccordionPersonReplyComponent.vue";

export default {
  name: "AccordionTriadsRepliesComponent",
  components: { AccordionPersonReplyComponent },
  props: {
    triad: {
      type: Array,
      required: true,
    },
    crew: {
      type: Array,
      required: true,
    },
    questionnaire: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      apprenticeReply: {},
      apprenticeMasterReply: {},
      tutorReply: {},
      studeaManagerReply: {},
    };
  },
  mounted() {
    this.apprenticeReply = this.questionnaire.dashboardData[this.triad.id]
      ? this.questionnaire.dashboardData[this.triad.id].apprentice
      : {};
    this.tutorReply = this.questionnaire.dashboardData[this.triad.id]
      ? this.questionnaire.dashboardData[this.triad.id].tutor
      : {};
    this.apprenticeMasterReply = this.questionnaire.dashboardData[this.triad.id]
      ? this.questionnaire.dashboardData[this.triad.id].apprenticeMaster
      : {};
    this.studeaManagerReply = this.questionnaire.dashboardData[this.triad.id]
      ? this.questionnaire.dashboardData[this.triad.id].studeaManager
      : {};
  },
  methods: {
    manage(payload) {
      this.$emit("manage", payload);
    },
  },
};
</script>

<style lang="scss" scoped></style>
