<template>
  <div class="widget faq">
    <div class="widget-heading flex-md-row flex-column items-center">
      <div>
        <h2 class="textStyle h-auto">
          {{
            $t("triadTrainingList") +
            (design && design.label
              ? design.label.toLowerCase()
              : $t("promotion"))
          }}
        </h2>
        <span class="fst-italic">
          {{ textInfoTrainingCourse() }}
        </span>
      </div>
      <div>
        <button
          v-if="isFiltered"
          class="btn-icon me-1"
          v-tooltip="{ title: $t('init_search') }"
          type="button"
          @click="resetSearch"
        >
          <FontAwesomeIcon icon="fa-light fa-arrows-rotate" />
        </button>
        <button
          class="btn-icon"
          v-tooltip="{ title: $t('search_motor') }"
          type="button"
          @click="openSearch"
        >
          <FontAwesomeIcon icon="fa-light fa-magnifying-glass" />
        </button>
      </div>
    </div>
    <div class="widget-content faq-layouting position-relative">
      <div class="d-flex justify-content-end mb-4">
        <div class="widget-search d-flex mt-2 mt-md-0 justify-content-end">
          <ModelComponent :model="search" @input="filter" />
          <button
            class="btn btnStyle btn-primary ms-2"
            :key="key"
            v-tooltip="{
              title: this.archiveTooltip,
            }"
            @click="archive(archived ? 1 : 2)"
          >
            <FontAwesomeIcon v-if="!archived" icon="fa-light fa-archive" />
            <FontAwesomeIcon v-else icon="fa-light fa-badge-check" />
          </button>
        </div>
      </div>

      <InfiniteScrollComponent
        :busy="busy"
        :complete="complete"
        ref="infinite"
        @load="load"
      >
        <div class="fq-tab-section">
          <div
            id="trainingCourseList"
            class="accordion"
            v-if="Object.keys(trainingCourses).length"
          >
            <div
              v-for="trainingCourse in trainingCourses"
              :key="trainingCourse.id"
              class="card"
            >
              <div class="card-header" role="tab">
                <div
                  :aria-controls="'collapse-' + trainingCourse.id"
                  :aria-expanded="false"
                  :data-bs-target="'#collapse-' + trainingCourse.id"
                  class="mb-0"
                  data-bs-toggle="collapse"
                  role="menu"
                  @click="loadTriads(trainingCourse)"
                >
                  <span class="faq-q-title textStyle">
                    <span :class="{ green: !this.archived, red: this.archived }"
                      ><FontAwesomeIcon
                        :icon="
                          'fa-light fa-' + (this.archived ? 'archive' : 'check')
                        "
                    /></span>
                    {{ trainingCourse.displayName
                    }}<span v-if="trainingCourse.loading"
                      ><span
                        class="me-1 spinner-border loading align-self-center loader-sm"
                      ></span>
                      <span class="d-none d-md-inline"
                        >Chargement des trînomes...</span
                      ></span
                    ></span
                  >
                  <div
                    v-if="trainingCourse.isStudeaManager"
                    class="like-faq d-flex align-items-center"
                    @click="$event.stopPropagation()"
                  >
                    <span class="d-inline-block triadNb"
                      >{{ trainingCourse.nbTriad }} livrets</span
                    >
                    <router-link
                      :to="{
                        name: 'studeaManagerHome',
                        params: { trainingCourseId: trainingCourse.id },
                      }"
                      class="settings d-none"
                      v-tooltip="{
                        title: $t('studea_manager.subscriptions_dashboard'),
                      }"
                    >
                      <FontAwesomeIcon
                        class="icon"
                        icon="fa-light fa-pen-field"
                      />
                    </router-link>

                    <router-link
                      :to="{
                        name: 'studeaManagerHome',
                        params: { trainingCourseId: trainingCourse.id },
                      }"
                      class="settings"
                      v-tooltip="{
                        title:
                          $tc('studea_manager.home.title', 1) +
                          $tc(
                            'studea_manager.trainingCourse.title',
                            1,
                          ).toLowerCase(),
                      }"
                    >
                      <FontAwesomeIcon class="icon" icon="fa-light fa-gear" />
                    </router-link>
                  </div>
                </div>
              </div>
              <div
                v-if="!loading"
                :id="'collapse-' + trainingCourse.id"
                :aria-labelledby="'collapse-' + trainingCourse.id"
                class="collapse"
                :class="{
                  show:
                    (currentTrainingCourse &&
                      trainingCourse.id === currentTrainingCourse.id) ||
                    (trainingCourse.triads &&
                      Object.keys(trainingCourse.triads).length &&
                      isSearching),
                  archive: this.archived,
                }"
                data-bs-parent="#trainingCourseList"
              >
                <TriadUsersListComponent
                  :search="false"
                  :trainingCourse="trainingCourse"
                  :isSearch="isSearching"
                />
              </div>
            </div>
          </div>
          <div v-else>
            {{
              archived
                ? $t("dashboard.no_training_course_archived")
                : $t("dashboard.no_training_course_actived")
            }}
          </div>
        </div>
        <BlockLoader :loading="listLoading" />
      </InfiniteScrollComponent>
      <ModalFormComponent
        :models="models"
        :title="
          $tc('global.search.title', 1, {
            name: this.$tc('admin.training.title', 2).toLowerCase(),
          })
        "
        @search="searchTraining"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import TriadUsersListComponent from "@/components/triad/TriadUsersListComponent.vue";
import ModelComponent from "@/components/form/ModelComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { i18n } from "@/i18n";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import InfiniteScrollComponent from "@/components/utilities/InfiniteScrollComponent.vue";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";
import { apiConnection } from "@/services/api-connection";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";

export default {
  name: "TrainingCourseListBlockComponent",
  components: {
    ModalFormComponent,
    InfiniteScrollComponent,
    BlockLoader,
    FontAwesomeIcon,
    ModelComponent,
    TriadUsersListComponent,
  },
  props: {
    adminButton: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      models: [],
      listLoading: false,
      loadingId: null,
      isSearching: false,
      archived: false,
      archiveTooltip: "",
      busy: false,
      complete: false,
      isFiltered: false,
      key: 0,
      search: {
        vars: {
          block_prefixes: ["", "text"],
          name: "training-course-search",
          id: "training-course-search",
          value: "",
          attr: {
            placeholder: this.$t("dashboard.triad_search_placeholder"),
          },
        },
      },
    };
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
      currentTrainingCourse: (store) => store.trainingCourse,
      count: (store) => store.count,
    }),
    ...mapState(useTriadStore, {
      triads: (store) => store.triads,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
    }),
  },
  mounted() {
    this.initTooltipTitle(!this.archived ? "archived" : "active");
  },
  watch: {
    design() {
      this.initTooltipTitle(!this.archived ? "archived" : "active");
    },
  },
  methods: {
    loadTriads(trainingCourse) {
      if (
        trainingCourse &&
        (!trainingCourse.triads || !Object.keys(trainingCourse.triads).length)
      ) {
        this.loading = true;
        window.localStorage.setItem(
          "studea-oldTrainingCourse",
          trainingCourse.id,
        );
        this.fetchTrainingCourseTriads({
          id: trainingCourse.id,
          toggle: true,
          archive: this.archived ? 2 : 1,
          search: this.search.vars.value,
        }).then(() => {
          this.loading = false;
        });
      }
    },

    load(page) {
      this.busy = true;
      console.log(
        parseInt(window.localStorage.getItem("studea-oldTrainingCourse")),
      );
      trainingCourseManager
        .loadTrainingCourseList(
          this.archived ? 2 : 1,
          this.search.vars.value,
          page,
          parseInt(window.localStorage.getItem("studea-oldTrainingCourse")),
        )
        .then((res) => {
          this.complete = Object.values(res.trainingCourses).length < 10;
          this.addTrainingCourses(res.trainingCourses);
          this.busy = false;
        });
    },

    filter(value) {
      if (value && value.length >= 2) {
        this.isSearching = true;
        this.search.vars.value = value;
      }
      if (this.isSearching) {
        this.listLoading = true;
        this.page = 0;
        this.fetchTrainingCourseList({
          search: this.search.vars.value,
          archive: this.archived ? 2 : 1,
          page: this.page,
        }).then(() => {
          this.listLoading = false;
        });
      }
    },

    archive(isArchived) {
      this.archived = isArchived === 2;
      this.initTooltipTitle(!this.archived ? "archived" : "active");
      if (this.search.vars.value) {
        this.isSearching = true;
      }
      this.$refs.infinite.page = 0;
      this.listLoading = true;
      this.fetchTrainingCourseList({
        search: this.search.vars.value,
        archive: isArchived,
        page: 0,
      }).then(() => {
        this.listLoading = false;
      });
    },

    textInfoTrainingCourse() {
      let label = "";
      const plural = this.count ? 2 : 1;
      const designLabel = this.design && this.design.label;
      const prefix = designLabel === "Groupe" ? "male_" : "female_";

      label = this.$tc("youHaveAccess", 1, {
        number: this.count,
        label: designLabel
          ? designLabel.toLowerCase() + (this.count ? "s" : "")
          : this.$tc("promotion", plural),
        active: !this.archived
          ? this.$tc(prefix + "active", plural)
          : this.$tc(prefix + "archived", plural),
      });
      return label;
    },

    initTooltipTitle(type) {
      if (this.design && this.design.label) {
        const plural = this.design.label.toLowerCase() === "groupe" ? 2 : 1;
        if (type === "archived") {
          this.archiveTooltip = i18n.global.tc(
            "dashboard.archived_training_course",
            plural,
            {
              label: this.design.label.toLowerCase(),
            },
          );
        }
        if (type === "active") {
          this.archiveTooltip = i18n.global.tc(
            "dashboard.unarchived_training_course",
            plural,
            {
              label: this.design.label.toLowerCase(),
            },
          );
        }
      }
      this.key++;
    },

    openSearch() {
      this.models = [];
      apiConnection.get("/app/training-course/search/form").then((response) => {
        if (response) {
          this.models = response;
          this.loading = false;
        }
      });
    },

    resetSearch() {
      this.search.vars.value = null;
      this.listParams = [];
      this.isFiltered = false;
      this.listLoading = true;
      trainingCourseManager
        .loadTrainingCourseListWithQuery(this.listParams)
        .then((response) => {
          this.setTrainingCourseList(response.trainingCourses);
          this.setCount(response.count);
          this.loadTriads(response.trainingCourses[0]);
          this.listLoading = false;
        });
    },

    setParams(params) {
      return (
        params +
        "?archive=" +
        (this.archived ? "1" : "0") +
        "&length=10&page=0&search=" +
        (this.search.vars.value ? this.search.vars.value : "") +
        "&id="
      );
    },

    searchTraining(params) {
      this.listLoading = true;
      trainingCourseManager
        .loadTrainingCourseListWithQuery(this.setParams(params))
        .then((response) => {
          this.isFiltered = true;
          this.setTrainingCourseList(response.trainingCourses);
          this.loadTriads(response.trainingCourses[0]);
          this.setCount(response.count);
          this.listLoading = false;
        });
    },
    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseTriads: "fetchTrainingCourseTriads",
      addTrainingCourses: "addTrainingCourses",
      fetchTrainingCourseList: "fetchTrainingCourseList",
      setCount: "setCount",
      setTrainingCourseList: "setTrainingCourseList",
    }),
  },
};
</script>

<style lang="scss" scoped>
.widget {
  #trainingCourseList {
    display: flex;
    flex-direction: column;
  }

  .loading {
    margin-left: 20px;
    width: 15px;
    height: 15px;
  }

  .settings {
    width: 35px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      font-size: 20px;
      color: $green;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: var(--primary-color);

      @include up-to-md {
        height: 0;
        width: 0;
      }
    }
  }

  .collapse {
    &.archive {
      background: $light-red;
    }
  }
}

.triadNb {
  font-size: 12px;
  font-style: italic;
  margin-right: 5px;
}

.faq-q-title {
  @include up-to-md {
    justify-content: flex-start !important;
  }

  .green {
    @include up-to-md {
      margin-right: 5px;
    }
  }
}

.like-faq {
  justify-content: space-between;
  @include up-to-md {
    margin-top: 15px;
    margin-left: 18px;
    justify-content: inherit;
  }
}
</style>
