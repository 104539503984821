<template>
  <div class="widget">
    <div class="widget-heading">
      <h2 v-if="title" class="textStyle d-flex">
        <button
          class="btn-icon me-2"
          v-tooltip="{ title: $t('back') }"
          @click="$router.back()"
        >
          <FontAwesomeIcon icon="fa-light fa-arrow-left" />
        </button>
        <span>
          {{ title }}
        </span>
      </h2>
    </div>
    <div v-if="models && Object.keys(models).length" class="widget-content">
      <slot name="infos"></slot>
      <FormComponent
        :models="models"
        :hideSubmit="hideSubmit"
        @formCancel="formCancel"
        @formSubmit="formSubmit"
        @update="update"
        @toggle="toggle"
      />
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import FormComponent from "@/components/FormComponent.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "FormBlockComponent",
  components: { FontAwesomeIcon, BlockLoader, FormComponent },
  props: {
    models: {
      type: [Array, Object],
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    formSubmit(payload) {
      this.$emit("submit", payload);
    },
    update() {
      this.$emit("update");
    },
    toggle() {
      this.$emit("toggle");
    },
    formCancel() {
      this.$emit("formCancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  min-height: 400px;
}
</style>
