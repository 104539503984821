<template>
  <div>
    <div :id="itemModel.vars.id">
      <label
        v-if="itemModel.vars.label"
        :class="
          'form-label' +
          (itemModel.vars.label_attr && itemModel.vars.label_attr.class
            ? ' ' + itemModel.vars.label_attr.class
            : '') +
          (itemModel.vars.required ? ' required' : '')
        "
      >
        {{ index ? index + ". " : "" }}{{ itemModel.vars.label
        }}{{ itemModel.vars.required ? "*" : "" }}
        <span
          v-if="itemModel.vars.help && !isMobile()"
          v-tooltip="{ title: itemModel.vars.help }"
        >
          <FontAwesomeIcon icon="fa-light fa-circle-question" />
        </span>
      </label>
      <div v-if="isMobile() && itemModel.vars.help" class="form-info">
        <FontAwesomeIcon icon="fa-light fa-circle-question" />
        {{ itemModel.vars.help }}
      </div>
      <div
        v-if="
          Object.keys(itemModel.vars.choices).length > 1 && !itemModel.vars.hide
        "
        class="mb-1 d-flex align-items-center justify-content-between"
      >
        <div>
          <button
            class="all textStyle"
            type="button"
            :disabled="itemModel.vars.attr && itemModel.vars.attr.readonly"
            @click="checkAll($event)"
          >
            {{ $t("All") }}
          </button>
          /
          <button
            class="none textStyle"
            type="button"
            :disabled="itemModel.vars.attr && itemModel.vars.attr.readonly"
            @click="checkNone($event)"
          >
            {{ $t("None") }}
          </button>
        </div>
        <div class="d-flex justify-content-end textStyle" v-if="grouped">
          {{ $t("trainingCoursesSelected") }}
          <SwitchTypeComponent :model="switchModel" @update="filterList" />
        </div>
      </div>
      <div v-if="!grouped">
        <CheckboxInputComponent
          v-if="choices && choices.length"
          :choices="choices"
          :model="itemModel"
          @setChecked="setChecked"
          @focus="focus"
        />
        <div v-else class="empty_data">
          {{ $t("global.empty.no_data_available") }}
        </div>
      </div>
      <div v-else class="" id="checkbox-accordion">
        <div class="group" v-for="(group, index) in choices" :key="group.group">
          <h2 class="textStyle group__heading" :id="'heading-' + index">
            <span
              :data-bs-toggle="isAccordion() ? 'collapse' : ''"
              :data-bs-target="'#collapse-' + index"
              :aria-expanded="group.active"
              :aria-controls="'collapse-' + index"
              class="textStyle group__title"
              :class="{
                collapsed: group.active,
              }"
              >{{ group.group }}</span
            >
            <div class="mb-1 d-flex align-items-center">
              <div
                class="textStyle group__controls"
                v-if="Object.keys(group.choices).length > 1"
              >
                <button
                  class="all textStyle"
                  type="button"
                  :disabled="
                    itemModel.vars.attr && itemModel.vars.attr.readonly
                  "
                  @click="check($event, true, index, group)"
                >
                  {{ $t("All") }}
                </button>
                /
                <button
                  class="group__button none textStyle"
                  type="button"
                  :disabled="
                    itemModel.vars.attr && itemModel.vars.attr.readonly
                  "
                  @click="check($event, false, index, group)"
                >
                  {{ $t("None") }}
                </button>
              </div>
              <button
                class="group__toggle"
                :class="{
                  collapsed: !group.active,
                }"
                type="button"
                :data-bs-toggle="isAccordion() ? 'collapse' : ''"
                :data-bs-target="'#collapse-' + index"
                :aria-expanded="group.active"
                :aria-controls="'collapse-' + index"
              >
                <FontAwesomeIcon
                  class="ms-2 textStyle"
                  v-if="isAccordion()"
                  icon="fa-light fa-chevron-down"
                />
              </button>
            </div>
          </h2>
          <div
            :id="'collapse-' + index"
            class="accordion-collapse collapse"
            :class="{
              show: isAccordion() && group.active,
              collapse: isAccordion(),
              'accordion-collapse': isAccordion(),
            }"
            :aria-labelledby="'heading-' + index"
          >
            <CheckboxInputComponent
              :choices="group.choices"
              :model="itemModel"
              :readonly="itemModel.vars.attr && itemModel.vars.attr.readonly"
              :filtered="filtered"
              @setChecked="setChecked"
              @focus="focus"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="itemModel.vars.error" class="invalid-feedback d-block">
      {{ itemModel.vars.error }}
    </div>
  </div>
</template>

<script>
import { formValidator } from "@/services/form/form-validator";
import { toRaw } from "vue";
import CheckboxInputComponent from "@/components/form/CheckboxInputComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { formManager } from "@/services/form/form-manager";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";

export default {
  name: "CheckboxTypeComponent",
  data() {
    return {
      itemModel: this.model,
      grouped: false,
      choices: [],
      filtered: false,
      switchModel: {
        vars: {
          label: "",
          value: false,
        },
      },
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    "itemModel.vars.choices"() {
      this.processChoices();
    },

    "model.vars.value"() {
      if (this.itemModel.vars.toggler_target) {
        this.$emit("toggle", this.itemModel);
      }
    },
  },
  mounted() {
    this.processChoices();
  },
  methods: {
    setChecked(event = null) {
      this.itemModel.vars.value = this.itemModel.vars.choices;
      let valid = formValidator.validCheckbox(this.itemModel.vars.value);
      if (!valid && this.itemModel.vars.required) {
        this.itemModel.vars["error"] = this.$t("requiredField");
      } else {
        delete this.itemModel.vars.error;
        if (event && event.target) {
          event.target.classList.add("is-valid");
        }
      }

      this.$emit("update", this.itemModel.vars.value);
      if (this.itemModel.vars.toggler_target) {
        this.$emit("toggle", this.itemModel);
      }
      this.$emit("draft", this.itemModel);
    },

    isAccordion() {
      return formManager.isAccordion(this.itemModel);
    },

    isMobile() {
      return window.innerWidth < 992;
    },

    focus() {
      this.$emit("focus");
    },

    checkAll(e) {
      this.check(e, true);
      if (this.isAccordion()) {
        const collapses = document.querySelectorAll(".group__toggle.collapsed");
        for (let i = 0; i < collapses.length; i++) {
          if (collapses[i].classList.contains("collapsed")) {
            collapses[i].click();
            setTimeout(() => {
              collapses[i].classList.remove("collapsed");
            }, 50);
          }
        }
      }
    },

    checkNone(e) {
      this.check(e, false);
    },

    check(event, value, index = null, group = null) {
      event.stopPropagation();
      event.preventDefault();
      if (group) {
        group.active = value;
      }
      if (
        this.itemModel &&
        this.itemModel.vars.choices &&
        Object.keys(this.choices).length
      ) {
        if (typeof index !== "number") {
          Object.values(this.choices).forEach((choice) => {
            if (this.grouped) {
              choice.choices.forEach((option) => {
                if (!option.disabled) {
                  option.checked = value;
                }
              });
            } else {
              if (!choice.disabled) {
                choice.checked = value;
              }
            }
          });
        } else {
          Object.values(this.choices[index].choices).forEach((option) => {
            if (!option.disabled) {
              option.checked = value;
            }
          });
        }
        this.setChecked();
      }
    },

    filterList(value) {
      this.filtered = value;
    },

    processChoices() {
      let choices = Object.values(this.itemModel.vars.choices);
      let index = 0;

      if (choices && choices.length) {
        choices.forEach((choice) => {
          if (formManager.isEntityType(this.itemModel)) {
            let data;
            if (this.itemModel.vars.data) {
              data = this.itemModel.vars.data.find(
                (item) => item.id === choice.data.id,
              );
            }
            if (data) {
              choice.checked = true;
            }
          } else if (
            this.itemModel.vars.data &&
            this.itemModel.vars.data.length
          ) {
            choice.checked =
              this.itemModel.vars.data &&
              this.itemModel.vars.data.includes(choice.data);
          }
          if (choice.choices && Object.keys(choice.choices).length) {
            let active = false;
            Object.values(choice.choices).forEach((choice) => {
              choice.checked =
                this.itemModel.vars.data &&
                this.itemModel.vars.data.includes(choice.data);

              const regex = /^.+__(.+)__$/;
              if (choice.label.match(regex)) {
                choice.badge = choice.label.match(regex)[1];
                choice.label = choice.label.replace(/__.+__/, "");
              }
              if (!active) {
                active = !index || choice.checked || choice.badge;
              }
              index++;
            });
            this.choices.push({
              group: choice.label,
              active: active,
              choices: Object.values(toRaw(choice.choices)),
            });
            this.grouped = true;
          }
        });

        if (!this.grouped) {
          this.choices = choices;
        }
      } else {
        this.choices = [];
      }
      this.itemModel.vars.value = this.choices;
    },
  },
  components: { SwitchTypeComponent, FontAwesomeIcon, CheckboxInputComponent },
};
</script>

<style lang="scss" scoped>
.all,
.none {
  padding: 0;
  background: none;
  border: none;

  &:hover {
    text-decoration: underline;
  }
}

.group {
  margin-top: 15px;

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background: none;
    border: none;
    border-bottom: 1px dashed $m-color_4;
    width: 100%;
  }

  &__title {
    margin-right: 15px;
    position: relative;
    font-size: 18px;
    width: 100%;
    flex: 1;
    cursor: pointer;
  }

  &__controls {
    font-size: 14px;
  }

  &__toggle {
    background: none;
    border: none;

    svg {
      transform: rotate(180deg);
      transition: all 300ms ease-in-out;
    }

    &.collapsed {
      svg {
        transform: rotate(0);
      }
    }
  }
}

#duplication_trainingCourseDestination {
  position: absolute;
  top: 0;
  width: 50%;

  @include up-to-md {
    position: relative;
    width: 100%;
  }
}

.empty_data {
  color: $light-grey;
}
</style>
